import React from "react";
import { login } from "../../api";
import "./signin.css";
import { TextField } from "@mui/material";

// components
import Headbar from "../headbar/headbar";
import Copyright from "../copyright/copyright";

export default class DownloadSignInPage extends React.Component {
  constructor(props) {
    document.title = "OT.AI Platform - Secure FTP Portal";
    super(props);
    this.state = {
      username: "",
      password: "",
      fail: "",
      account_type: "",
      loading: false,
    };
    this.submitLogin = this.submitLogin.bind(this);
    this.enterClick = this.enterClick.bind(this);
  }

  async submitLogin() {
    this.setState({ loading: true });
    let user_info = await login(this.state.username, this.state.password);
    this.setState({ loading: false });
    if (
      !user_info.hasOwnProperty("token") &&
      user_info.hasOwnProperty("message")
    ) {
      this.setState({ fail: user_info.message });
      return;
    }
    switch (user_info.user_type) {
      case "DownloadUser":
        window.location.assign(
          `#/download?token=${user_info.token}`
        );
        break;
      case "UploadUser":
        window.location.assign(
          `#/upload?token=${user_info.token}`
        );
        break;
      case "AdminUser":
        window.location.assign(`#/admin?token=${user_info.token}`);
        break;
      default:
        window.location.assign(`#/download_sigin`);
        break;
    }
  }

  enterClick(e) {
    if (e.key === "Enter") {
      this.submitLogin();
    }
  }

  render() {
    return (
      <div className="login-page">
        <Headbar title="ObjectSecurity Secure FTP Portal" />

        <h1>Sign in</h1>
        <div className="login-center">
          <TextField
            value={this.state.username}
            onKeyDown={this.enterClick}
            onChange={(e) => this.setState({ username: e.target.value })}
            label="Username"
            fullWidth
            className="username-input"
          />
          <TextField
            value={this.state.password}
            onKeyDown={this.enterClick}
            onChange={(e) => this.setState({ password: e.target.value })}
            label="Password"
            type="password"
            fullWidth
          />
          <p style={{ textAlign: "left", width: "100%" }}>
            Sign in with the username and password you configured.
          </p>

          <div className="row">
            {this.state.loading && <div className="lds-dual-ring"></div>}
            {!this.state.loading && <p>{this.state.fail}</p>}

            <button onClick={this.submitLogin}>Sign in</button>
          </div>
        </div>
        <Copyright />
      </div>
    );
  }
}
