import React from "react";
import "./download_page.css";

// icons
import { Info, Package } from "react-feather";
import { downloadAsset, getAssetInfo, isValidSession } from "../../api";

// components
import Headbar from "../headbar/headbar";
import Copyright from "../copyright/copyright";

export default class DownloadPage extends React.Component {
  constructor(props) {
    document.title = "OT.AI Platform - Download";
    super(props);
    const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
    this.token = urlParams.get("token");
    this.state = {
      assets: [],
    };
    this.init = this.init.bind(this);
    this.download = this.download.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    if (!(await isValidSession(this.token))) {
      window.location.assign("#/download_signin");
    }
    let assets = await getAssetInfo(this.token);
    this.setState({
      assets,
    });
  }

  async download(asset) {
    downloadAsset(this.token, asset._id);
  }

  render() {
    return (
      <div className="download-page">
        <Headbar title="ObjectSecurity Secure FTP Portal" logout />
        <div className="download-center">
          {this.state.assets.map((asset) => {
            return (
              <div className="download" onClick={() => this.download(asset)}>
                <Package className="icon" />
                <div>
                  <h2>{asset.key}</h2>
                  <p>{`Checksum: ${asset.checksum}`}</p>
                </div>
              </div>
            );
          })}
          <a
            onClick={() =>
              alert(
                "Your set of downloads will appear here. Click an item above to start the download for that item. If the download doesn't start, or if no items appear, please contact ObjectSecurity."
              )
            }
          >
            <Info className="icon" /> What is this?
          </a>
        </div>
        <Copyright />
      </div>
    );
  }
}
