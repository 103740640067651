import { useState } from "react";
import "./dsa_page.css";

// icons
import { Download } from "react-feather";

// components
import Headbar from "../headbar/headbar";
import Copyright from "../copyright/copyright";

export default function DSAPage() {
  document.title = "OT.AI Platform - DSA";

  const urlParams = new URLSearchParams(window.location.href.split("?")[1]);
  const token = urlParams.get("token");

  let [done, setDone] = useState(false);

  let year = new Date().getFullYear();
  if (year > 2022) {
    year = `2022 - ${year}`;
  }

  return (
    <div className="eula-page">
      <Headbar title="ObjectSecurity Secure FTP Portal" />
      <h1>Data Sharing Agreement</h1>
      <div className="eula-page-center">
        <div
          className="eula-content"
          onScroll={() => {
            if (!done) {
              setDone(true);
            }
          }}
        >
          <h1>OBJECTSECURITY LLC BINARY DATA SHARING AND USE AGREEMENT</h1>
          <p>
            <b>ATTENTION:</b>
            THIS IS A LEGALLY-BINDING CONTRACT. PLEASE READ THIS BINARY DATA
            SHARING AND USE AGREEMENT (“BDSUA”) CAREFULLY BEFORE USING THE
            OBJECTSECURITY™ OT.AI™ PLATFORM CORPUS CONTRIBUTION PORTAL
            (“OBJECTSECURITY OT.AI PLATFORM CORPUS CONTRIBUTION PORTAL”) MADE
            AVAILABLE TO YOU BY OBJECTSECURITY LLC (“OBJECTSECURITY” OR “DATA
            USER”).
            <br />
            <br />
            THIS AGREEMENT GOVERNS YOUR (“DATA PROVIDER”) RELATIONSHIP WITH
            OBJECTSECURITY WITH RESPECT TO THE DATASETS YOU WILL OR HAVE
            PROVIDED TO OBJECTSECURITY, INCLUDING BINARY CODE WITH KNOWN
            VULNERABILITIES AND WEAKNESSES, AS WELL AS “CLEAN” BINARY CODE AND
            OTHER RELATED DATA (“TRAINING DATA”) FOR PURPOSE OF ENHANCING THE
            CAPABILITIES OF OBJECTSECURITY™ OT.AI™ PLATFORM FOR OPERATIONAL
            TECHNOLOGY (OT) AND INDUSTRIAL CONTROL SYSTEMS (ICS) BINARY
            VULNERABILITY ANALYSIS.
            <br />
            <br />
            THIS AGREEMENT INCORPORATES THE OBJECTSECURITY PRIVACY POLICY
            LOCATED
            <a
              style={{
                display: "inline",
                color: "var(--sec-color)",
                marginLeft: 5,
                marginRight: 5,
                fontSize: 15,
                fontWeight: 600,
              }}
              target="_blank"
              href="https://support.objectsecurity.com/support/solutions/articles/72000587084-OBJECTSECURITY-PRIVACY-POLICY"
            >
              HERE,
            </a>
            WHICH IS MADE A PART OF THIS AGREEMENT.
            <br />
            <br />
          </p>

          <p>
            <b>Provision of Data</b>
            <br />
            <br />
            You agree to make the Training Data available to ObjectSecurity for
            download and include any relevant documentation and instructions for
            accessing the Training Data.
            <br />
            <br />
            If applicable, you may be asked to provide ObjectSecurity with
            certain personal information relating to an identified or
            identifiable natural person and any other information that
            constitutes personal data or personal information under any
            applicable law. This information will be held and used in accordance
            with the ObjectSecurity Privacy Policy:
            <a
              style={{
                display: "inline",
                color: "var(--sec-color)",
                marginLeft: 5,
                marginRight: 5,
                fontSize: 15,
                fontWeight: 600,
              }}
              target="_blank"
              href="https://support.objectsecurity.com/support/solutions/articles/72000587084-objectsecurity-privacy-policy"
            >
              here.
            </a>
            <br />
            <br />
            You agree that you will supply accurate and complete information to
            ObjectSecurity and that you will update such information as needed.
            <br />
            <br />
            When you upload Training Data to the ObjectSecurity OT.AI Platform
            Corpus Contribution Portal, you hereby grant to ObjectSecurity a
            non-exclusive, worldwide, perpetual, irrevocable, fully-paid,
            royalty-free, and fully sublicensable and transferable license under
            any and all rights, including intellectual property rights that you
            own or control to publish, reproduce, distribute, perform, display,
            adapt, modify and prepare derivative works of, and otherwise use
            your Training Data in accordance with the terms and conditions of
            this Agreement. ObjectSecurity will not pay you or otherwise
            compensate you for any Training Data you provide to ObjectSecurity.
            <br />
            <br />
          </p>

          <p>
            <b>Use of Data</b>
            <br />
            <br />
            ObjectSecurity will use the Training Data solely for the purpose of
            enhancing the capabilities of the ObjectSecurity OT.AI Platform,
            including, without limitation, building and/or improving binary
            analysis assessments (“Assessments”) and machine learning algorithms
            (“LearningModified on Tue, 13 Dec 2022 at 07:56 AM Models”) and
            applying the Learning Models to commercial versions of the
            ObjectSecurity OT.AI Platform and related technology applications.
            <br />
            <br />
            You hereby agree and acknowledge that ObjectSecurity has no
            obligations to return or delete the Training Data and can use the
            Training Data in perpetuity.
            <br />
            <br />
          </p>

          <p>
            <b>Rights in Trained Model</b>
            <br />
            <br />
            If it is determined that you have any rights in the Learning Models
            (models that are modified based on the Training Data), you
            irrevocably grant ObjectSecurity a sublicensable license to all such
            rights. You hereby acknowledge and agree that you have no rights or
            interest in any Assessments, Learning Models, or other o
            <br />
            <br />
          </p>

          <p>
            <b>Terms and Termination.</b>
            <br />
            <br />
            The term of this Agreement (“Terms”) shall begin when you start
            uploading Training Data to the ObjectSecurity OT.AI Platform Corpus
            Contributor Portal and shall continue in perpetuity unless otherwise
            terminated by ObjectSecurity.
            <br />
            <br />
          </p>

          <p>
            <b>Confidentiality of Training Data</b>
            <br />
            <br />
            ObjectSecurity agrees to take reasonable steps to protect the
            confidentiality of the Training Data while in the possession or
            control of ObjectSecurity, except that ObjectSecurity may freely use
            or disclose any portion of the Training Data that: (i) was lawfully
            in possession of ObjectSecurity prior to the time of disclosure by
            Data Provider; (ii) becomes publicly available without a breach of
            this Agreement by ObjectSecurity; (iii) is received by
            ObjectSecurity lawfully from another source without any
            corresponding obligation of confidentiality, or (iv) is
            independently developed by or for ObjectSecurity.
            <br />
            <br />
            ObjectSecurity may disclose the Training Data if and as required by
            law, provided that ObjectSecurity notifies you to enable you to seek
            a protective order or another appropriate remedy.
            <br />
            <br />
            ObjectSecurity may not disclose the Training Data to any third party
            except to its employees, contractors, and consultants
            (“Representatives”) and then only on a need-to-know basis under
            nondisclosure obligations at least as protective as this Agreement.
            <br />
            <br />
          </p>

          <p>
            <b>Warranties and Disclaimers</b>
            <br />
            <br />
            YOU HEREBY WARRANT THAT (I) YOU ARE NOT AWARE OF ANY CONTRACTUAL OR
            OTHER RESTRICTIONS AND LIMITATIONS ON THE TRAINING DATA THAT WOULD
            LIMIT OBJECTSECURITYʼS USE OF THE ASSESSMENTS OR LEARNING MODELS AND
            APPLYING THE ASSESSMENTS OR LEARNING MODELS TO COMMERCIAL VERSIONS
            OF THE OBJECTSECURITY PLATFORM AND RELATED APPLICATIONS AS
            CONTEMPLATED BY THIS AGREEMENT, AND YOU HAVE SUFFICIENT RIGHTS WITH
            RESPECT TO THE TRAINING DATA FOR TRAINING AND DEVELOPING OF THE
            ASSESSMENTS OR LEARNING MODELS AND TO GRANT THE LICENSES AND
            PERMISSIONS CONTEMPLATED HEREUNDER.
            <br />
            <br />
            OBJECTSECURITY REPRESENTS AND WARRANTS THAT IT HAS SUFFICIENT RIGHTS
            WITH RESPECT TO APPLYING THE ASSESSMENTS OR LEARNING MODELS TO
            COMMERCIAL VERSIONS OF THE OBJECTSECURITY OT.AI PLATFORM AND RELATED
            APPLICATIONS AND TO DISTRIBUTE THE SAME.
            <br />
            <br />
            IN NO EVENT SHALL OBJECTSECURITY OR ANY OF ITS AFFILIATES BE LIABLE
            FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, PUNITIVE, SPECIAL, OR
            INCIDENTAL DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS
            OF BUSINESS, CONTRACT, REVENUE, DATA, INFORMATION, OR BUSINESS
            INTERRUPTION), UNDER ANY THEORY OF LIABILITY, RESULTING FROM,
            ARISING OUT OF OR IN CONNECTION WITH THE USE OF ANY TRAINING DATA,
            EVEN IF OBJECTSECURITY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES. ANY ACTION BROUGHT AGAINST OBJECTSECURITY PERTAINING TO OR
            IN CONNECTION WITH ANY TRAINING DATA, ASSESSMENTS, OR LEARNING
            MODELS MUST BE COMMENCED AND NOTIFIED TO OBJECTSECURITY IN WRITING
            WITHIN ONE (1) YEAR AFTER THE DATE THE CAUSE FOR ACTION AROSE.
            <br />
            <br />
          </p>

          <p>
            <b>Indemnification</b>
            <br />
            <br />
            You hereby agree to indemnify and hold harmless ObjectSecurity, its
            affiliates, officers, directors, agents, and employees, from any
            expense, loss, claim, damage, fine, penalty, or liability, including
            reasonable fees for attorneys and other professionals, payable under
            any judgment, verdict, court order or settlement, to the extent
            resulting from any claim, demand, action, suit, arbitration, or
            other proceeding initiated by any third party, including the
            assessment, claim or demand by a governmental agency or entity,
            arising out of your breach of the warranties or obligations set
            forth in this Agreement.
            <br />
            <br />
          </p>

          <p>
            <b>General </b>
            <br />
            <br />
            This Agreement shall be governed by, construed, and enforced in
            accordance with the laws of the State of Delaware without reference
            to conflicts of law rules and principles.
            <br />
            <br />
            This Agreement will be enforced to the fullest extent permitted by
            applicable law. If, for any reason, any provision of this Agreement
            is held to be invalid or unenforceable under applicable law to any
            extent, then (a) such provision will be interpreted, construed, or
            reformed to the extent reasonably required to render the same valid,
            enforceable and consistent with the original intent underlying such
            provision and (b) such invalidity or unenforceability will not a
            <br />
            <br />
            Any proceedings to resolve or litigate any dispute in any forum in
            connection with this Agreement shall be conducted solely on an
            individual basis. Neither you nor ObjectSecurity shall seek to have
            any dispute or controversy in connection with this Agreement heard
            as a class action or in any other proceeding in which either party
            acts or proposes to act in a representative capacity. Parties
            further agree that no proceeding shall be combined with another
            proceeding without the prior written consent of the other party.
            <br />
            <br />
            No action, regardless of form, arising out of the transactions under
            this Agreement may be brought by either party hereto more than one
            (1) year after the cause of action has occurred or was discovered to
            have occurred, except that an action for infringement of
            intellectual property rights may be brought within the maximum
            applicable statutory period.
            <br />
            <br />
            QQuestions about this Agreement may be submitted to
            info@objectsecurity.com. When submitting an inquiry, please use the
            subject line: BDUSA.
            <br />
            <br />
            <a
              style={{
                display: "inline",
                color: "var(--sec-color)",
                marginRight: 5,
                fontSize: 15,
                fontWeight: 600,
              }}
              target="_blank"
              href="https://support.objectsecurity.com/support/solutions/articles/72000587084-objectsecurity-privacy-policy"
            >
              Please also read our Privacy Policy.
            </a>
            <br />
            <br />
            Copyright © {year} ObjectSecurity LLC. All rights reserved.
            <br />
            <br />
          </p>
        </div>

        <div className="row">
          <div
            className="download-eula"
            onClick={() => {
              var link = document.createElement("a");
              link.href =
                "ObjectSecurity LLC Binary Data Sharing and Use Agreement _ ObjectSecurity OT.AI Platform.pdf";
              link.download =
                "ObjectSecurity LLC Binary Data Sharing and Use Agreement _ ObjectSecurity OT.AI Platform.pdf";
              link.dispatchEvent(new MouseEvent("click"));
            }}
          >
            <Download className="icon" />
            <h3>Download and Print DSA</h3>
          </div>

          {!done && (
            <h3 className="please-read">
              Please read the entire DSA to continue.
            </h3>
          )}
          {done && (
            <button
              onClick={() => {
                window.location.assign("#/");
              }}
              className="eula-disagree"
            >
              Disagree
            </button>
          )}
          {done && (
            <button
              onClick={() => {
                if (token) {
                  window.location.assign(`#/setup?token=${token}`);
                  return;
                }
                window.location.assign("#/signin");
              }}
              className="eula-agree"
            >
              Agree
            </button>
          )}
        </div>
      </div>
      <Copyright />
    </div>
  );
}
